<template>
  <div :style="getElementStyle" v-if="data.properties.filed_content!= 'Hide'">
    <div>
      <div style="display: flex; align-items: center">
        <label  v-if="hasLabel && !data.properties.hideLabel" for="text" style="margin-right: 5px; white-space: nowrap"
          >{{ data.label }}
        </label>
        <span v-if="data.description" style="margin-right: 5px">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
      </div>
    </div>
    <div v-if="loading" :style="getStyle" v-loading="loading"></div>
    <div style="background-color: white; border: 1px solid grey" v-else>
      <CustomEntityCalendarWidgets
        :calenderData="calenderData"
        :selectedEntities="selectedCalendarData"
        :customDashboard="{}"
        :parentDate="currentDate"
        :parentView="currentView"
        :isFromApplicationuser="false"
        :fromTemplate="true"
      >
      </CustomEntityCalendarWidgets>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FieldStylesHelper from "@/mixins/FieldStylesHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import {
  fetchEntitiesAndFilters,
  fetchAllEntityFiltersByEntity,
} from "@/repo/filtersRepo";
import { postAPICall } from "@/helpers/httpHelper";
import moment from "moment";

export default {
  name: "CalenderView",
  components: {
    CustomEntityCalendarWidgets: () =>
      import("@/components/widgets/EntityCalendarWidgets.vue"),
  },
  props: ["data", "form", "hasLabel", "allEntitiesData", "allFormTemplates"],
  mixins: [FieldStylesHelper, CustomDashboardConfig],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    selectedEntities() {
      return this.allEntitiesData;
    },
    getStyle() {
      return `height:${this.data.height}px; width: ${this.data.width}px; background-color: white; border: 1px solid grey`;
    },
  },

  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      showLabel: true,
      inputTextAlignmentValue: "left",
      loading: false,
      currentDate: new Date(),
      currentView: "Month",
      allEntityFilters: [],
      getTemplateDateFields: [],
      calenderData: [],
      selectedCalendarData: [],
      dependedFields: [],
      dependedFieldsData: {},
    };
  },
  mounted() {
    this.setDependedFilters();
    this.getEntityDateFields();
    this.getNecessaryInfo({ date: new Date(), view: "Month" });
   
  },
  methods: {
    setDependedFilters() {
      (this.data.selectedEntities || []).forEach((et) => {
        (et.filters || []).forEach((filter) => {
          if (filter?.data_source == "TEMPLATE" && filter.value_field) {
            this.dependedFields.push(filter.value_field);
            this.$set(
              this.dependedFieldsData,
              filter.value_field,
              this.form[filter.value_field]
            );
          }
        });
      });
    },
    mapFilters(filters) {
      return (filters || []).map((filter, index) => {
        if (index == 0) {
          filter["query_type"] = "AND";
        }
        if (filter?.data_source == "TEMPLATE" && filter.value_field) {
          filter.value = this.form[filter.value_field];
        }
        return filter;
      });
    },
    getEntityFilters(row) {
      if (
        row &&
        row.includes("#") &&
        row.split("#")[0] &&
        !this.allEntityFilters[row.split("#")[0]]
      ) {
        let index = (
          this.data && this.data.selectedEntities
            ? this.data.selectedEntities
            : []
        ).findIndex((e) => e.field == row);
        if (
          index > -1 &&
          (!this.data.selectedEntities[index].custom_message ||
            (typeof this.data.selectedEntities[index].custom_message ==
              "object" &&
              Object.keys(this.data.selectedEntities[index].custom_message)
                .length == 0))
        ) {
          let entity = (this.selectedEntities || []).find(
            (e) => e._id == row.split("#")[0]
          );
          if (entity?.primaryFields && entity?.primaryFields[0]) {
            let temp = (this.allFormTemplates || []).find(
              (e) => e._id == entity?.primaryFields[0].template_id
            );
            this.data.selectedEntities[index]["custom_message"] = {
              type: "doc",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "mention",
                      attrs: {
                        id: temp?.slug + "#" + entity?.primaryFields[0].key,
                        label: null,
                      },
                    },
                    {
                      type: "text",
                      text: " " + row.split("#")[2],
                    },
                  ],
                },
              ],
            };
          }
        }
        let field = this.getTemplateDateFields.find((e) => e.key == row);

        if (field?.inputType) {
          this.data.selectedEntities[index].inputType = field.inputType;
        }
        this.fetchEntityFilters(row.split("#")[0]);
      }
    },
    getEntityDateFields() {
      this.getTemplateDateFields = [];
      if (this.data?.selectedEntities && this.data.selectedEntities.length) {
        this.data.selectedEntities.forEach((en) => {
          let entity_id = en.entity_id;
          if (entity_id) {
            const entity = this.selectedEntities.find((e) => e._id == entity_id);
            const templateIds = (
              entity && entity.templates ? entity.templates : []
            ).flatMap((e) => e.template_id);
            const entityTemplates = (this.allFormTemplates || []).filter(
              (e) => (templateIds || []).indexOf(e._id) !== -1
            );
            (entityTemplates || []).forEach((e) => {
              let data = JSON.parse(JSON.stringify(e));
              this.getTemplateDateFields = [
                ...this.getTemplateDateFields,
                ...this.getTemplateFieldsByType(
                  data,
                  ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
                  entity
                ),
              ];
            });
          }
        });
      }
    },
    async fetchEntityFilters(entityId) {
      const getAllEntityFilters = await fetchAllEntityFiltersByEntity({
        entityId: entityId,
      });
      if (getAllEntityFilters) {
        this.allEntityFilters.push({
          entity_id: entityId,
          filters: getAllEntityFilters,
        });
      }
    },
    async getNecessaryInfo({ date, view }) {
      if (this.data.selectedEntities && this.data.selectedEntities.length) {
        this.loading = true;
        let filters = [],
          entities = [],
          params = {
            entities: [],
          };
        await Promise.all(
          this.data.selectedEntities.map(async (e) => {
            this.getEntityFilters(e.field);
            if (
              e &&

              e.field &&
              e.field.includes("#") &&
              e.field.split("#").length > 2
            ) {
              let [entity_id, template_id, key] = e.field.split("#");
              let obj = {};
              if (e?.custom_message?.content[0]?.content) {
                let fields = [];
                (e.custom_message.content[0].content || []).forEach((el) => {
                  if (
                    el?.type == "mention" &&
                    el?.attrs?.id &&
                    el.attrs.id.includes("#")
                  ) {
                    let [slug, key] = el?.attrs?.id.split("#");
                    let temp = (this.allFormTemplates || []).find(
                      (e) => e.slug == slug
                    );
                    if (temp?._id) {
                      fields.push(temp._id + "#" + key);
                    }
                  }
                });
                obj["custom_fields"] = fields;
              }
              if (entity_id && template_id && key) {
                obj["entity_id"] = entity_id;
                obj["filter_id"] = e.filter;
                if (e.filter) {
                  filters.push(e.filter);
                  entities.push(entity_id);
                }
                if (e.filters?.length) {
                  obj["filters"] = this.mapFilters(e.filters);
                }
                obj["fields"] = [template_id + "#" + key];
                let field = template_id + "#" + key;
                let dateFilter = this.getYearFilter(date.getFullYear(), field);
                if (obj["filters"]) {
                  obj["filters"] = [...obj["filters"], ...[dateFilter]];
                } else {
                  obj["filters"] = [dateFilter];
                }
                if (this.entityFilters && this.entityFilters[entity_id]) {
                  if (obj["filters"]) {
                    obj["filters"] = [
                      ...obj["filters"],
                      ...this.entityFilters[entity_id],
                    ];
                  } else {
                    obj["filters"] = this.entityFilters[entity_id];
                  }
                }
                let index = params.entities.findIndex(
                  (e) => e.entity_id == entity_id
                );
                if (
                  this.checkGlobalFilterOnStats?.apply_on &&
                  this.checkGlobalFilterOnSingleStat(entity_id)
                ) {
                  obj["filters"] = this.addGlobalFilters(
                    obj["filters"] || [],
                    entity_id,
                    this.checkGlobalFilterOnSingleStat(entity_id)
                  );
                }
                if (index == -1) {
                  params.entities.push(obj);
                } else {
                  if(obj["fields"]?.length) {
                    params.entities[index].fields = [
                    ...params.entities[index].fields,
                    ...obj["fields"],
                  ];
                  }
                  if(obj["custom_fields"]?.length) {
                    params.entities[index].custom_fields = [
                    ...params.entities[index].custom_fields,
                    ...obj["custom_fields"],
                  ];
                  }
                }
              }
            }
          })
        );
        if (filters && filters.length) {
          // await this.$store.dispatch("filters/fetchFiltersListByIds", {
          //   filters: filters,
          //   entities: entities,
          // });
          const getAllFilters = await fetchEntitiesAndFilters({
            filters: filters,
            entities: entities,
          });
          if (getAllFilters?.length) {
            this.quick_filters = [];
            let filtersCount = 0;
            if (getAllFilters.filters) {
              getAllFilters.entities.forEach((entity) => {
                if (entity && filtersCount < 5) {
                  let filters = getAllFilters.filters.filter(
                    (e) => e.entity_id == entity._id
                  );
                  if (filters && filters.length) {
                    let selectedEntityFields = [];
                    entity.templates.forEach((e) => {
                      if (e && e.template_id && !e.is_repeatable) {
                        selectedEntityFields = [
                          ...selectedEntityFields,
                          ...this.getFields(e.template_id),
                        ];
                      }
                    });
                    let quickFilters = [];
                    filters.forEach((fl) => {
                      if (fl.quick_filters && quickFilters.length < 5) {
                        quickFilters = [...quickFilters, ...fl.quick_filters];
                        filtersCount = filtersCount + fl.quick_filters.length;
                      }
                    });
                    this.quick_filters.push({
                      fields: selectedEntityFields,
                      filters: quickFilters || [],
                      entity_id: entity._id,
                    });
                  }
                }
              });
            }
            // this.getAllFilters.forEach((e) => {
            //   if (e?.quick_filters) {
            //     this.quick_filters = [...this.quick_filters, ...e.quick_filters];
            //   }
            // });
          }
        }
        this.currentDate = date;
        this.currentView = view;
        // await Promise.all([
          // this.$store.dispatch("entities/fetchEntities", {
          //   get_all: true,
          // }),
          // this.$store.dispatch("entities/fetchCalendarData", params),
        // ]);
        const response = await postAPICall(
          "POST",
          "/getCalender/calender",
          params
        );
        if (response?.data) {
          if (window.Worker) {
            try {
              /* This is large computation task so we sending this to web workers */
              const worker = new Worker("worker.js");
              let that = this;
              worker.onmessage = function (event) {
                const result = event.data;
                that.calenderData = result;
                console.log("==========", result, response.data);
                that.loading = false;
                worker.terminate();
              };

              worker.onerror = function () {
                worker.terminate();
                that.calenderData = that.getConfigData(response.data);
                that.loading = false;
              };

              worker.postMessage({
                process_name: "SET_CALENDER",
                data: response.data,
                componentData: {
                  calenderDateField: this.data.selectedEntities,
                },
                getAllCompanyTemplatesData: {
                  data: this.allFormTemplates,
                },
              });
            } catch (e) {
              this.calenderData = this.getConfigData(response.data);
              this.loading = false;
              console.log("Worker Error", e);
            }
          } else {
            this.calenderData = this.getConfigData(response.data);
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
        this.selectedCalendarData = this.selectedEntities;
      }
    },
    getYearFilter(year, field) {
      return {
        field: field,
        operator: "real_time",
        value: new Date(year - 1, 11, 31, 18, 0, 0, 0),
        value_type: "",
        value_source: "",
        data_type: "DATE",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        selected_date_picker: "custom_date_range",
        today: new Date(year, 11, 30, 18, 0, 0, 0),
      };
    },
    getConfigData(getAllCalendarsData) {
      if (getAllCalendarsData.length) {
        let result = [];
        getAllCalendarsData.forEach((el) => {
          if (el?.date && Object.keys(el.date).length > 0) {
            Object.keys(el.date).forEach((temp) => {
              if (temp && el.date[temp] && Object.keys(el.date[temp])) {
                Object.keys(el.date[temp]).forEach((e) => {
                  let id = el.entity_id + "#" + temp + "#" + e;
                  let message = this.data?.selectedEntities.find(
                    (e) => e.field == id
                  );
                  let customMessage = this.getCalendarMessage(el, message);
                  result.push({
                    customData: customMessage,
                    entity_id: el.entity_id,
                    entity_data_id: el._id,
                    title: customMessage,
                    start: this.getFormatted(
                      message?.inputType,
                      el.date[temp][e]
                    ),
                    everyYear: message?.show_every_year,
                    inputType: message?.inputType,
                    color: message?.color,
                    applyBackground: message?.applyBackground,
                    dates: this.getFormatted(
                      message?.inputType,
                      el.date[temp][e]
                    ),
                  });
                });
              }
            });
          }
        });
        return result;
      }
      return [];
    },
    getFormatted(type, value) {
      if (type == "DATE_TIME_RANGE" || type == "DATE_RANGE") {
        if (typeof value == "object" && value.length) {
          return [
            moment.utc(value[0]).local().format("YYYY-MM-DD HH:mm:ss"),
            moment.utc(value[1]).local().format("YYYY-MM-DD HH:mm:ss"),
          ];
        }
        return [];
      }
      return moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss");
    },
    getCalendarMessage(data, field) {
      let str = "";
      if (
        data &&
        data?.custome_data &&
        field?.custom_message?.content[0]?.content
      ) {
        (field?.custom_message?.content[0]?.content || []).forEach((e) => {
          if (e?.type == "text") {
            str = str + e.text;
          } else if (
            e?.type == "mention" &&
            e?.attrs?.id &&
            e.attrs.id.includes("#")
          ) {
            let [slug, key] = e?.attrs?.id.split("#");
            let temp = (this.allFormTemplates || []).find(
              (e) => e.slug == slug
            );
            if (
              temp?._id &&
              data?.custome_data[temp._id] &&
              data?.custome_data[temp._id][key]
            ) {
              if (data?.custome_data[temp._id][key + "/name"]) {
                str = str + data?.custome_data[temp._id][key + "/name"];
              } else if (this.checkDate(data?.custome_data[temp._id][key])) {
                str =
                  str +
                  moment(data.custome_data[temp._id][key]).format(
                    "MM-DD-YYYY HH:mm:A"
                  );
              } else {
                str = str + data?.custome_data[temp._id][key];
              }
            }
          }
        });
      }
      return str;
    },
 

    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    inputTextAlignment() {
      return this.data.styles && this.data.styles.inputTextAlignments
        ? this.data.styles.inputTextAlignments
        : "left";
    },

    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    form: {
      async handler(data) {
        if (this.dependedFields && this.dependedFields.length) {
          let currentData = {};
          this.dependedFields.forEach((f) => {
            this.$set(currentData, f, data[f]);
          });
          if (
            JSON.stringify(currentData) !==
            JSON.stringify(this.dependedFieldsData)
          ) {
            this.dependedFields.forEach((f) => {
              this.$set(this.dependedFieldsData, f, data[f]);
            });
            console.log("dependedFields", this.dependedFields);
            this.getNecessaryInfo({
              date: this.currentDate,
              view: this.currentView,
            });
          }
        }
      },
      deep: true,
    },
    allFormTemplates: {
    handler(newTemplates) {
      if (newTemplates.length) {
        this.getNecessaryInfo({
          date: this.currentDate,
          view: this.currentView,
        });
      }
    },
    deep: true,
  },
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.slv {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
}
</style>
